import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import { GatsbyImage } from 'gatsby-plugin-image';

const CompaniesAndCertifications = ({ images }) => {
  const [logosOrder, setLogosOrder] = useState([
    {
      altName: 'Gasco Logo',
      className: 'gascoLogo',
      relativePath: 'GascoLogo.png',
      src: '',
    },
    {
      altName: 'EFE Logo',
      className: 'equalSizeLarger',
      relativePath: 'EFELogo.png',
      src: '',
    },
    {
      altName: 'Oxxean Logo',
      className: 'equalSize',
      relativePath: 'OxxeanLogo.png',
      src: '',
    },
    {
      altName: 'CPT Logo',
      className: 'cptLogo',
      relativePath: 'CPTLogo.png',
      src: '',
    },
    {
      altName: 'Naviera Austral Logo',
      className: 'navieraLogo',
      relativePath: 'NavieraAustralLogo.png',
      src: '',
    },
    {
      altName: 'Puelche Logo',
      className: 'equalSizeLarger',
      relativePath: 'PuelcheLogo.jpg',
      src: '',
    },
    {
      altName: 'Comaco Logo',
      className: 'equalSize',
      relativePath: 'ComacoLogo.png',
      src: '',
    },
    {
      altName: 'Ametel Logo',
      className: 'ametelLogo',
      relativePath: 'AmetelLogo.png',
      src: '',
    },
    {
      altName: 'Iso 9001 Logo',
      className: 'iso9001Logo',
      relativePath: "Iso9001quality.png",
      src: '',
    },
    {
      altName: 'Iso 27001 Logo',
      className: 'iso27001Logo',
      relativePath: 'Iso27001.png',
      src: '',
    },
    {
      altName: 'IQNET Logo',
      className: 'iso27001Logo',
      relativePath: 'IQNET.png',
      src: '',
    },
  ]);


  useEffect(() => {
    setLogosOrder((currentLogos) =>
      currentLogos.map((logo) => {
        const matchingImage = images?.find(
          (image) => image.node.relativePath === logo.relativePath
        );

        return matchingImage ?
          { ...logo, src: matchingImage.node.childImageSharp.gatsbyImageData }
        :
          logo;
      })
    );
  }, [images]);


  return (
    <Row justify='center' className='companies-certifications-wrapper mt-lg-100 mt-md-70 mt-sm-70 mt-xs-70'>
      <Col lg={24} xs={22}>
        <h5 className='text-center mb-30'>Confían en <italic>Nosotros</italic></h5>
      </Col>
      <Col xl={19} lg={22} xs={22} className="logos-container">
        <Row gutter={[0, 70]} className="pt-40 pb-20 pl-30 pr-30">
        {
          logosOrder?.[0]?.src !== '' && logosOrder.map((image, index) => {
            if (!['Iso 9001 Logo', 'Iso 27001 Logo', 'IQNET Logo'].includes(image.altName)) {
              return (
                <Col lg={6} xs={24} className="image-container" key={index}>
                  <GatsbyImage image={image.src} alt={image.altName} className={image.className} />
                </Col>
              )
            }
          })
        }
        </Row>                        
      </Col>
      <Col xl={19} lg={22} xs={22} className="certifications-container mt-lg-100 mt-md-70 mt-sm-70 mt-xs-70">
      {
        logosOrder?.[0]?.src &&
          <Row justify='center' align='middle' className="pt-lg-80 pt-md-50 pt-sm-50 pt-xs-50 pb-70 pl-lg-30 pl-lg-20 pl-lg-20 pl-xs-20 pr-lg-30 pr-md-20 pr-sm-20 pr-xs-20" gutter={[0, 30]}>
            <Col lg={10} xs={24}>
              <h5><italic>Certificaciones:</italic></h5>
            </Col>
            <Col lg={8} xs={24}>
              <GatsbyImage image={logosOrder[8].src} alt='Iso 9001 Logo' className="iso9001Logo" />
            </Col>
            <Col lg={6} xs={24}>
              <Row gutter={30}>
                <Col><GatsbyImage image={logosOrder[9].src} alt='Iso 27001 Logo' className="iso27001Logo" /></Col>
                <Col><GatsbyImage image={logosOrder[10].src} alt='IQNET Logo' className="iso27001Logo" /></Col>
              </Row>
            </Col> 
          </Row>
        }
      </Col> 
    </Row>
  );
};

export default CompaniesAndCertifications;